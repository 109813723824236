import React from "react";
import PropTypes from "prop-types";
import { TextArea } from "@assistansbolaget/gat-components";

import "./style.css";

const TextAreaInput = ({ input, meta: { error, touched }, ...rest }) => {
  return (
    <div className="textAreaInput">
      <TextArea
        error={touched && error ? "error" : ""}
        type={rest.type}
        label={rest.label}
        {...rest}
        {...input}
        style={{ fontSize: "13px" }}
      />
    </div>
  );
};

/* eslint-disable */
TextAreaInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default TextAreaInput;
